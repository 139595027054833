<template>
  <iq-card class="border shadow-md">
    <template v-slot:body>
      <b-overlay
        :show="loading"
        rounded
        opacity="1"
        spinner-variant="primary"
      >
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <h5>
              {{ label }}
            </h5>
            <h6 v-if="subLabel">
              <small>{{ subLabel }}</small>
            </h6>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-end">
          <h1 :class="countClass">
            <strong>{{ currency ? '₱ ' : '' }}{{ count }}</strong>
          </h1>
        </div>
      </b-overlay>
    </template>
  </iq-card>
</template>
<script>
import { core } from '@/config/pluginInit'

export default {
  name: 'UserCard',
  middleware: ['auth'],
  props: {
    currency: {
      type: [String, Boolean],
      default: () => {
        return false
      }
    },
    count: {
      type: [String, Number],
      required: true,
      default: () => {
        return 0
      }
    },
    label: {
      type: String,
      required: true,
      default: () => {
        return ''
      }
    },
    subLabel: {
      type: String,
      default: () => {
        return null
      }
    },
    countClass: {
      type: String,
      required: true,
      default: () => {
        return ''
      }
    },
    loading: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data () {
    return {

    }
  },

  mounted () {
    core.index()
  }

}
</script>
